.input {
    width: 100%;
    &__label {
        display: block;
        margin-bottom: 18px;
        font-size: 15px;
        font-weight: 800;
        color: #fff;
    }
    &__wrapper {
        display: block;
    }
    &__field {
        width: 100%;
        padding-bottom: 12px;
        font-family: var(--Roboto);
        font-size: 18px;
        background-color: transparent;
        border-bottom: 2px solid;
        transition: .2s ease-out;
        &:not(.notWhite) {
            color: #fff;
            border-bottom-color: currentColor;
        }
        &.notWhite {
            color: rgb(var(--mainColor));
            border-bottom-color: currentColor;
        }
        &::placeholder {
            color: inherit;
        }
        &:focus {
            border-bottom-color: rgb(var(--dodgerBlue));
        }
    }
    input {
        height: 32px;
    }
    textarea {
        height: 102px;
    }
}