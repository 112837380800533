/* Default */
@import "default/variables.css";
@import "default/reset.css";
@import "default/grid.css";
@import "default/typography.css";
@import "default/fonts.css";

/* Components */ 
@import "../templates/components/logo/logo.css";
@import "../templates/components/socials/socials.css";
@import "../templates/components/callback/callback.css";
@import "../templates/components/burger/burger.css";
@import "../templates/components/buttons/buttons.css";
@import "../templates/components/titles/title.css";
@import "../templates/components/inputs/inputs.css";
@import "../templates/components/modal/modal.css";

/* Blocks */
@import "../templates/blocks/header/header.css";
@import "../templates/blocks/nav/nav.css";
@import "../templates/blocks/menu/menu.css";
@import "../templates/blocks/consultation/consultation.css";
@import "../templates/blocks/footer/footer.css";