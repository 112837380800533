.consultBtn {
    display: block;
    width: 100%;
    max-width: 212px;
    padding: 19px 38px;
    margin: 0 auto;
    color: #fff;
    background-color: rgb(var(--dodgerBlue));
    border-radius: 30px;
}

@media (max-width: 992px) {
    .consultBtn {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

@media (max-width: 767px) {
    .consultBtn {
        padding-top: 13px;
        padding-bottom: 13px;
    }
}