.pageHeading {
    margin-bottom: 50px;
}

.pageTitle,
.title {
    font-weight: 800;
    color: rgb(var(--blackRock));
}

.pageTitle {
    margin-bottom: 14px;
    font-size: 44px;
}

.pageSubtitle {
    font-size: 30px;
    font-weight: 100;
    color: rgb(var(--dodgerBlue));
}

.title {
    font-size: 30px;
}