.header {
    padding-top: 68px;
    &.isFloat {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
    }
    &:not(.isFloat) {
        padding-bottom: 68px;
        margin-bottom: 68px;
        background-color: rgb(var(--blackRock));
    }
    &__inner {
        align-items: center;
    }
    &__logo {
        margin-right: auto;
    }
    &__nav {
        margin-right: 75px;
    }
    &__socials {
        margin-right: 24px;
    }
    &__callback {
        align-self: flex-end;
    }
    &__burger {
        display: none;
    }
}

@media (max-width: 1300px) {
    .header {
        padding-top: 30px;
        &:not(.isFloat) {
            padding-bottom: 30px;
        }
        &__nav {
            margin-right: 45px;
        }
    }
}

@media (max-width: 1080px) {
    .header {
        &__nav {
            display: none;
        }
        &__burger {
            display: block;
            margin-left: 24px;
        }
    }
}

@media (max-width: 767px) {
    .header {
        &__callback {
            align-self: center;
        }
    }
}

@media (max-width: 575px) {
    .header {
        padding-top: 12px;
        &:not(.isFloat) {
            padding-bottom: 12px;
        }
        &__logo {
            width: 92px;
        }
    }
}

@media (max-width: 420px) {
    .header {
        &__socials {
            display: none;
        }
    }
}