.consultation {
    padding-top: 52px;
    padding-bottom: 106px;
    background-color: rgb(var(--blackRock));
    &__inner {
        &-cell {
            &:first-child {
                width: 41.6%;
            }

            &:last-child {
                width: 58.4%;
            }
        }
    }
    &__form {
        &-list {
            margin-bottom: 48px;
        }
        &-field {
            &:not(:last-child) {
                margin-bottom: 42px;
            }
        }
        &-submit {
            margin: 0;
        }
    }
    &__uptitle,
    &__title,
    &__subtitle {
        color: #fff;
    }
    &__uptitle {
        margin-bottom: 34px;
        font-size: 30px;
        font-weight: 100;
    }
    &__title {
        margin-bottom: 6px;
        font-size: 48px;
        font-weight: 800;
    }
    &__subtitle,
    &__phone {
        font-size: 40px;
        font-weight: 300;
    }

    &__subtitle {
        margin-bottom: 26px;
        font-family: var(--Roboto);
    }

    &__phone {
        &-link {
            color: rgb(var(--dandelion));
        }
    }
}

@media (max-width: 992px) {
    .consultation {
        padding-bottom: 90px;
        &__inner {
            &-cell {
                &:first-child,
                &:last-child {
                    width: 100%;
                }

                &:first-child {
                    order: 2;
                    max-width: 546px;
                    margin: 0 auto;
                }

                &:last-child {
                    margin-bottom: 60px;
                }
            }
        }
        &__form {
            &-submit {
                display: block;
                margin: 0 auto;
            }
        }
        &__info {
            text-align: center;
        }
    }
}

@media (max-width: 767px) {
    .consultation {
        padding-bottom: 60px;
        &__form {
            &-field {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }
        }
        &__subtitle,
        &__phone {
            font-size: 32px;
        }
    }
}

@media (max-width: 575px) {
    .consultation {
        padding-bottom: 40px;
        &__inner {
            &-cell {
                &:last-child {
                    margin-bottom: 40px;
                }
            }
        }
        &__form {
            &-submit {
                margin: 0;
            }
        }
        &__info {
            text-align: left;
        }
        &__title {
            margin-bottom: 4px;
        }
        &__subtitle,
        &__phone {
            font-size: 28px;
        }
    }
}