.socials {
    &__list {
        list-style: none;
    }
    &__item {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

@media (max-width: 420px) {
    .socials {
        &__list {
            justify-content: center;
        }
    }
}