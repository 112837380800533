.modal {
    position: relative;
    width: 100%;
    max-width: 440px;
    padding: 54px 30px 20px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,.3);
    border-radius: 6px;
    &-wrapper {
        width: 100%;
        max-width: 732px;
    }
    &-head {
        margin-bottom: 35px;
    }
    &-close {
        position: absolute;
        top: 34px;
        right: 42px;
        width: 15px;
        height: 15px;
        color: rgb(var(--amaranth));
        transition: .2s ease-out;
        .icon-cross {
            width: 18px;
            height: 18px;
            stroke: currentColor;
            fill: currentColor;
        }
        &:hover {
            transform: scale(1.04);
        }
        &:active {
            transform: scale(.96);
        }
    }
    &-title {
        font-size: 24px;
        font-weight: 800;
        text-align: center;
    }
    &Form {
        &-list {
            margin-bottom: 45px;
        }
        &-input {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
        &-submit {
            width: 100%;
            height: 48px;
            font-size: 18px;
            font-weight: 900;
            color: #fff;
            background-color: rgb(var(--dandelion));
        }
    }
}

@media (max-width: 575px) {
    .modal {
        padding-right: 15px;
        padding-left: 15px;
        &-close {
            top: 15px;
            right: 15px;
        }
    }
}