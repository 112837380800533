.typography {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 25px;
        font-family: inherit;
        font-weight: 800;
        line-height: 1.2;
        color: rgb(var(--blackRock));
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 26px;
    }

    h4 {
        font-size: 22px;
    }

    h5 {
        font-size: 18px;
    }
    
    h6 {
        font-size: 14px;
    }

    p {
        &:not([class]) {
            margin-top: 0;
            margin-bottom: 25px;
            font-family: var(--Roboto);
            font-size: 13px;
            line-height: 20px;
            color: rgb(var(--revolver));
        }
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: rgb(var(--dodgerBlue));
        &:hover {
            text-decoration: underline;
        }
    }

    mark {
        padding: .2em;
        background-color: #fcf8e3;
    }

    small {
        font-size: 80%;
        font-weight: 400;
    }

    b, strong {
        font-weight: 800;
    }

    blockquote {
        margin: 0 0 16px;
    }

    dl, 
    ol, 
    ul {
        margin-top: 0;
        margin-bottom: 16px;
    }

    ol ol, 
    ol ul, 
    ul ol, 
    ul ul {
        margin-bottom: 0;
    }

    ul {
        list-style: none;
    }

    li {
        position: relative;
        padding-left: 28px;
        margin-bottom: 25px;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            width: 15px;
            height: 15px;
            border: 5px solid rgb(var(--blackRock));
            border-radius: 50%;
        }
    }

    table {
        border-collapse: collapse;
        border: 1px solid #ddd;
    }
    table th,
    table td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
    }
}