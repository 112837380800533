@font-face {
    font-family: "RobotoSlab";
    src: url("../assets/fonts/RobotoSlab/RobotoSlab-Thin.woff2") format("woff2"),
    url("../assets/fonts/RobotoSlab/RobotoSlab-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "RobotoSlab";
    src: url("../assets/fonts/RobotoSlab/RobotoSlab-Light.woff2") format("woff2"),
    url("../assets/fonts/RobotoSlab/RobotoSlab-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "RobotoSlab";
    src: url("../assets/fonts/RobotoSlab/RobotoSlab-Regular.woff2") format("woff2"),
    url("../assets/fonts/RobotoSlab/RobotoSlab-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "RobotoSlab";
    src: url("../assets/fonts/RobotoSlab/RobotoSlab-Bold.woff2") format("woff2"),
    url("../assets/fonts/RobotoSlab/RobotoSlab-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto/Roboto-Light.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Medium.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}