.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    &:not([data-size="small"]) {
        max-width: 1490px;
    }
    &[data-size="small"] {
        max-width: 1170px;
    }
}

.flexRow, 
.flexRow-C,
.flexColumn, 
.flexColumn-C, 
.flexWrap, 
.flexGrid { 
    display: flex;
}

.inlineFlex {
    display: inline-flex;
}

.flexColumn,
.flexColumn-C {
    flex-direction: column;
}

.flexRow-C,
.flexColumn-C {
    justify-content: center;
    align-items: center;
}

.flexWrap,
.inlineFlex,
.flexGrid {
    flex-wrap: wrap;
}

.flexGrid {
    margin-right: -15px;
    margin-left: -15px;
}

.flexCell {
    padding-right: 15px;
    padding-left: 15px;
}