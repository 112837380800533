.callback {
    &__phone {
        margin-bottom: 4px;
        font-size: 24px;
        color: #fff;
    }
    &__btn {
        font-family: var(--Roboto);
        font-size: 12px;
        text-transform: uppercase;
        color: rgb(var(--dandelion));
        border-bottom: 1px dashed currentColor;
        transition: .2s ease-out;
        &:hover {
            border-bottom-color: transparent;
        }
    }
}

@media (max-width: 767px) {
    .callback {
        &__phone {
            display: none;
        }
    }
}