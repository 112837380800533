.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: none;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(var(--blackRussian),.8);
    opacity: 0;
    transition: .4s ease-out;
    &.active {
        opacity: 1;
    }
    &__control {
        position: absolute;
        top: 30px;
        right: 30px;
    }
    &__close {
        position: relative;
        width: 24px;
        height: 24px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            width: 100%;
            height: 3px;
            background-color: #fff;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
    &__inner {
        justify-content: center;
        height: 100%;
    }
    &__socials {
        display: none;
    }
}

@media (max-width: 420px) {
    .menu {
        &__nav {
            margin-bottom: 30px;
        }
        &__socials {
            display: block;
        }
    }
}