.burger {
    position: relative;
    display: block;
    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
    }
    &__inner {
        justify-content: space-between;
        width: 21px;
        height: 11px;
    }
    &__stroke {
        width: 100%;
        height: 2px;
        background-color: #fff;

        &:first-child,
        &:last-child {
            transform-origin: left;
            transition: .2s ease-out;
        }
        &:first-child {
            transform: scaleX(.48);
        }
        &:last-child {
            transform: scaleX(.81);
        }
    }
    &:hover {
        .burger {
            &__stroke {
                &:first-child,
                &:last-child {
                    transform: scaleX(1);
                }
            }
        }
    }
}