:root {
    /* Colors */
    --mainColor: 0,0,0;
    --dandelion: 252,206,95;
    --blackRussian: 33,35,45;
    --dodgerBlue: 35,127,250;
    --manatee: 143,148,174;
    --blackRock: 38,39,55;
    --revolver: 56,56,59;
    --blueZodiac: 68,71,88;
    --echoBlue: 172,176,192;
    --comet: 106,109,125;
    --amaranth: 230,56,79;
    --mischka: 166,168,179;
    --lavender: 230,230,236;
    --whiteSmoke: 240,240,240;
    --spunPearl: 169,169,173;
    --mariner: 62,100,151;

    /* Fonts */
    --mainFont: "RobotoSlab", sans-serif;
    --Roboto: "Roboto", sans-serif;
}