.nav {
    &__list,
    &__submenu-list {
        list-style: none;
    }
    &__item {
        &:not(:last-child) {
            margin-right: 48px;
        }
        &.hasSubmenu {
            position: relative;
        }
    }
    &__link {
        position: relative;
        padding-bottom: 2px;
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgb(var(--dandelion));
            transform: scale(0);
            transition: .2s ease-out;
        }
        &:hover,
        &.active {
            &::after {
                transform: scale(1);
            }
        }
        &.active {
            color: rgb(var(--dandelion));
        }
    }
    &__submenu {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        padding-top: 10px;
        opacity: 0;
        transform: translateY(-10px);
        transition: .3s ease-out;
        &.active {
            opacity: 1;
            transform: translateY(0);
        }
        &-list {
            background-color: rgba(255,255,255,.92);
            border-top: 2px solid rgb(var(--dandelion));
        }
        &-link {
            padding: 8px;
            font-size: 14px;
            white-space: nowrap;
            color: rgb(var(--mainColor));
            &.active,
            &:hover {
                color: rgb(var(--dandelion));
            }
        }
    }
}

@media (max-width: 1300px) {
    .nav {
        &__item {
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}

@media (max-width: 1080px) {
    .nav {
        &__list {
            flex-direction: column;
        }
        &__item {
            text-align: center;
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
        &__link {
            display: inline-block;
        }
        &__submenu {
            position: static;
            opacity: 1;
            transform: none;
            transition: none;
            &.active {
                display: block;
            }
            &-list {
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }
}