footer {
    margin-top: auto;
}

.footer {
    position: relative;
    padding-bottom: 42px;
    background-color: rgb(var(--blackRock));
    &.hasCloud {
        .footer__cloud {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            img {
                width: inherit;
            }
        }
    }
    &__inner {
        position: relative;
        z-index: 1;
    }
    &__border {
        width: 100%;
        height: 2px;
        margin-bottom: 44px;
        border-top: 1px solid #1a1c25;
        border-bottom: 1px solid #292b37;
    }
    &__top {
        margin-bottom: 60px;
    }
    &__logo {
        flex-shrink: 0;
        width: 160px;
        margin-right: 30px;
    }
    &__local,
    &__info,
    &__payments {
        width: 100%;
    }
    &__local {
        max-width: 255px;
        margin-right: 62px;
    }
    &__info {
        max-width: 442px;
        margin-right: 36px;
    }
    &__payments {
        max-width: 332px;
    }
    &__title,
    &__date {
        color: rgb(var(--echoBlue));
    }
    &__title,
    &__date,
    &__copyright,
    &__text,
    &__link {
        font-weight: 800;
    }
    &__title {
        margin-bottom: 22px;
        font-size: 24px;
    }
    &__text,
    &__date {
        font-size: 18px;
    }
    &__text {
        color: rgb(var(--comet));
    }
    &__list {
        list-style: none;
        &-item {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
    &__link {
        text-decoration: underline;
        color: rgb(var(--dodgerBlue));
        &:hover {
            text-decoration: none;
        }
    }
    &__date,
    &__copyright {
        text-align: center;
    }
    &__date {
        margin-bottom: 74px;
    }
    &__copyright {
        color: rgb(var(--blueZodiac));
    }
}

@media (max-width: 1300px) {
    .footer {
        &__logo {
            width: 130px;
        }
        &__local {
            margin-right: 30px;
        }
    }
}

@media (max-width: 1080px) {
    .footer {
        &__top {
            flex-wrap: wrap;
        }
        &__info {
            max-width: calc(100% - calc(285px + 160px));
            margin-right: 0;
        }
        &__payments {
            width: 100%;
            margin-right: 30px;
        }
    }
}

@media (max-width: 767px) {
    .footer {
        &__top {
            justify-content: center;
        }
        &__local {
            margin-right: 0;
        }
        &__info {
            max-width: 100%;
            margin-top: 30px;
            text-align: center;
        }
    }
}

@media (max-width: 480px) {
    .footer {
        &__logo {
            margin-right: 0;
        }
        &__local {
            max-width: 100%;
            margin-top: 30px;
            text-align: center;
        }
        &__date {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 420px) {
    .footer {
        &__title {
            font-size: 22px;
        }
        &__text {
            font-size: 16px;
        }
    }
}